import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

interface FormState {
  dateTime: string;
  timeZone: string;
}

const initialState: FormState = {
  dateTime: "",
  timeZone: "",
};

export const botSlice = createSlice({
  name: "talkBot",
  initialState,
  reducers: {
    updateDateTime: (state, action: PayloadAction<string>) => {
      const newState = cloneDeep(state);
      newState.dateTime = action.payload;
      return newState;
    },
    updateTimeZone: (state, action: PayloadAction<string>) => {
      const newState = cloneDeep(state);
      newState.timeZone = action.payload;
      return newState;
    },
    clearSelection: () => initialState,
  },
});

export const { updateDateTime, updateTimeZone, clearSelection } =
  botSlice.actions;

export default botSlice.reducer;
