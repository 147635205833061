
import axiosInstance from './axiosInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const scheduleInterview = createAsyncThunk("scheduleInterview", async (payload:InterViewSchedule, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/interview/schedule',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const attemptInterview = createAsyncThunk("attemptInterview", async (payload:attemptInterview, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/interview/attempt',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const getScheduleList = createAsyncThunk("getScheduleList", async (payload:scheduleList, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/interview/getScheduleList',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });