import React, { useState } from "react";
import { Tabs, TabsHeader, TabsBody, Tab } from "@material-tailwind/react";
import { documentsTabs } from "../../utils/constant";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../components/CustomButton";
import { GridPaginationModel } from "@mui/x-data-grid";

const Documents: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("upload_policy");
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);

  const handleSearch = () => {};

  const handleTabChange = (newValue: string) => {
    setActiveTab(newValue);
  };

  const getButtonLabel = (): string => {
    switch (activeTab) {
      case "upload_policy":
        return "Create Upload Policy";
      case "upload_financial_statements":
        return "Create Financial Statement";
      case "upload_pictures":
        return "Upload New Pictures";
      case "upload_payroll_docs":
        return "Upload Payroll Docs";
      case "upload_invoices":
        return "Upload New Invoice";
      default:
        return "Create";
    }
  };

  const buttons = [
    { label: "Filter", icon: <FilterAltOutlinedIcon />, onClick: handleSearch },
    { label: getButtonLabel(), icon: <AddIcon />, onClick: handleSearch },
  ];

  const handlePaginationChange = (model: GridPaginationModel) => {
    setCurrentPage(model.page);
    setRowsPerPage(model.pageSize);
  };

  return (
    <div className="pl-4 pr-4">
      <Tabs value={activeTab}>
        <TabsHeader>
          {documentsTabs.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              className="mt-2 mb-2 ms-2 me-2"
              activeClassName="mt-2 mb-2 ms-2 me-2"
              onClick={() => handleTabChange(value)}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <div className="flex flex-row-reverse gap-5 mt-5">
          {buttons.map(({ label, icon, onClick }, index) => (
            <CustomButton
              key={index}
              className="md:w-auto bg-[#045373] rounded-lg pl-5 pr-5 font-semibold"
              label={label}
              icon={icon}
              onClick={onClick}
            />
          ))}
        </div>
        <TabsBody className="mt-5">
          {documentsTabs.map(({ value, component: Component }) => {
            if (value === activeTab) {
              return (
                <Component
                  key={value}
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  onPaginationChange={handlePaginationChange}
                />
              );
            }
            return null;
          })}
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default Documents;
