const Header: React.FC<{ isEditMode: boolean; toggleEditView: () => void }> = ({ isEditMode, toggleEditView }) => {
    return (
        <div className="flex mt-3 justify-between items-center cursor-pointer text-3xl bg-[#cfdce4] py-2">
            <div className="flex items-center pl-5">
                <span className="font-semibold text-base">Claim Details</span>
                <img src={"/images/claimDetails.png"} alt="Claim Details" className="w-12 h-10 ml-2" />
            </div>
            {!isEditMode ? (
                <img
                    src={"/images/claimEdit.png"}
                    alt="Edit Claim"
                    className="w-12 h-8 pr-5 cursor-pointer"
                    onClick={toggleEditView}
                />
            ) : (
                <img
                    src={"/images/eye.png"}
                    alt="View Claim"
                    className="w-12 h-8 pr-5 cursor-pointer"
                    onClick={toggleEditView}
                />
            )}
        </div>
    );
};

export default Header;