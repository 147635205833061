import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
interface stepperProps{
    activeStep: number;
    categoryRes:any
}
export default function VerticalLinearStepper({activeStep,categoryRes}:stepperProps) {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {categoryRes?.map((step:any, index:number) => (
          <Step key={step.categoryName}>
            <StepLabel
             className={"font-poppins text-sm antialiased"}
              optional={
                index === categoryRes.length ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.categoryName}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
   
    </Box>
  );
}