import axiosInstance from './axiosInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';
interface ClientPayload {
    data: string;
    pageNo: number;
    pageSize: number;
  }
  export const getClientList = createAsyncThunk("fetchClients", async (payload:ClientPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/client/getClients',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  });

  export const updateClientById = createAsyncThunk("updateClientById", async (payload:any, { rejectWithValue }) => {
    try {
      const clientId=payload?.clientId
      const payload1={...payload}
      delete payload1["clientId"]
      const response = await axiosInstance.put('/client/updateClient/'+clientId,payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  });

