import { cx } from "class-variance-authority";
import CustomButton from "../../components/CustomButton";
import SuccesImage from "../../assets/images/success.png";

import React from "react";
interface props {
  message: string;
  setShow?: any;
}
const SuccessPath = ({ message, setShow }: props) => {
  return (
    <div className="flex flex-col h-auto items-center">
      <img src={SuccesImage} width={"40%"} alt="success" />
      <h1 className="text-[#000] font-poppins mb-4 text-center  font-semibold h-auto">
        {message}
      </h1>
      {/* <div className="flex lg:flex-row md:flex-row sm:flex-row xs:flex-col gap-2 py-4 px-4">
        <CustomButton
       style={{width:100}}
       className={cx(
            "bg-[#1F7FBB]",
            "border-[#1F7FBB]",
            "shadow-md shadow-[#1F7FBB]",
            "!w-[100%]"
          )}
          label="Done"
          onClick={() => setShow(false)}
        />
        <CustomButton
          className={cx(
            "bg-[#000]",
            "border-[#000]",
            "shadow-md shadow-[#000000]"
          )}
          label="Back"
          onClick={() => setShow(false)}
        />
      </div> */}
    </div>
  );
};

export default SuccessPath;
