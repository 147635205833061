import { cx } from "class-variance-authority";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClientInformation from "./ClientInformation";
import ClaimInformation from "./ClaimInformation";
import InsuredInformation from "./InsuredInformation";
import CustomButton from "../../components/CustomButton";
import { Checkbox } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { getFileNumber, saveClaimData } from "../../api/claimApi";
import { ClaimData } from "./ClaimData";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { resetClaimData } from "src/store/slices/claimSlice";
import { setInsuredInfo } from "src/store/slices/claimSlice";
import { setClaimInfo } from "src/store/slices/claimSlice";
import FormInput from "src/components/FormInput";
import Loader from "src/components/loader";
import { useAppDispatch } from "src/store/hooks";
import { emailValidation } from "src/utils";
const RightSideSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { clientInfo, claimInfo, insuredInfo,isUniqueClaimNumber } = useSelector(
    (state: RootState) => state.claim
  );

  const areRequiredFieldsFilled = () => {
    return (
      claimInfo.fileNumber &&
      claimInfo.claimNumber &&
      claimInfo.assignee &&
      claimInfo.assigneeEmail &&
      claimInfo.dateOfLoss &&
      insuredInfo.insuredFirstName &&
      insuredInfo.insuredLastName &&
      insuredInfo.insuredTitle &&
      insuredInfo.insuredBusinessName &&
      insuredInfo.insuredPostalAddress &&
      insuredInfo.insuredPhoneNumber &&
      insuredInfo.insuredEmail &&
      insuredInfo.requiredOtherInfo === false
    );
  };

  const mutation = useMutation<any, Error, ClaimData>({
    mutationFn: (claimData) => saveClaimData(claimData),
    onSuccess: (data) => {
      dispatch(getFileNumber(""));
      if (data?.statusCode === 200 || data?.statusCode === 201) {
        toast.success("Claim details saved successfully", { position: "bottom-right" });
        dispatch(resetClaimData("ALL"));
      } else {
        toast.error(data?.data || data?.message, { position: "bottom-right" });
      }
    },
    onError: (error: Error) => {
      toast.error(error.message, {
        position: "bottom-center",
      });
      console.error("Error saving claim:", error);
    },
  });

  const checkMandatory = () => {
    let isError = false;
    if (
      !claimInfo.fileNumber ||
      !claimInfo.claimNumber ||
      !claimInfo.assignee ||
      !claimInfo.dateOfLoss ||
      !claimInfo.assigneeEmail ||
      !insuredInfo.insuredFirstName ||
      !insuredInfo.insuredLastName ||
      !insuredInfo.insuredBusinessName ||
      !insuredInfo.insuredPostalAddress ||
      !insuredInfo.insuredPhoneNumber ||
      !insuredInfo.insuredEmail
    ) {
      isError = true;
    }
    if (
      !clientInfo.clientId &&
      (!clientInfo?.firstName ||
        !clientInfo.lastName ||
        !clientInfo.clientTitle ||
        !clientInfo.email ||
        !clientInfo.phoneNumber)
    ) {
      isError = true;
    }
    return isError;
  };

  const handleSave = async() => {
 
    let moveOn = checkMandatory();
    if (!moveOn) {
      const dataToSave: any = {
        fileNumber: claimInfo.fileNumber,
        claimNumber: claimInfo.claimNumber,
        assignee: claimInfo.assignee,
        dateOfLoss: claimInfo.dateOfLoss,
        assigneeEmail: claimInfo.assigneeEmail,
        insuredFirstName: insuredInfo.insuredFirstName,
        insuredLastName:  insuredInfo.insuredLastName,
        insuredTitle: insuredInfo.insuredTitle,
        insuredBusinessName: insuredInfo.insuredBusinessName,
        insuredPostalAddress: insuredInfo.insuredPostalAddress,
        insuredPhoneNumber: insuredInfo.insuredPhoneNumber,
        insuredEmail: insuredInfo.insuredEmail,
        clientId: clientInfo.clientId,
        comments: clientInfo?.comments,
        requiredOtherInfo: insuredInfo.requiredOtherInfo,
        clientCompany:clientInfo?.clientCompany
      };
      if (!clientInfo.clientId) {
        dataToSave.newClientDetails = {
          FirstName: clientInfo?.firstName,
          MiddleName: "",
          Salutation: clientInfo.clientTitle,
          LastName: clientInfo?.lastName,
          BusinessCards: [
            {
              EmailAddresses: [clientInfo.email],
              PhoneNumbers: [{ Number: clientInfo.phoneNumber }],
            },
          ],
        };
      }
      if(!emailValidation(clientInfo.email) || !emailValidation(insuredInfo.insuredEmail)){
        toast.warn("Enter Valid emails", {
          position: "bottom-center",
        });
        return
      }
      if(!isUniqueClaimNumber){
        toast.warn("This claim number is alreay used. please use another claim number", {
          position: "bottom-center",
        });
        return
      }
      mutation.mutate(dataToSave);
    } else {
      toast.warn("Fill all mandatory fields before save", {
        position: "bottom-center",
      });
    }
  };
  const handleReset = () => {
    dispatch(resetClaimData("ALL"));
  };
  return (
    <div
      className={cx(
        "w-1/2 justify-center lg:w-1/2 md:w-1/2",
        "bg-cover bg-repeat",
        "bgimage"
      )}
    >
      <div
        className={cx(
          "form-card h-auto",
          "sm:top-[10%] sm:left-[5%] sm:w-[90%]",
          "md:top-[14%] md:left-[5%] md:w-[90%]",
          "lg:top-[14%] lg:left-[36%] lg:w-[63%]"
        )}
      >
        {mutation.isPending && <Loader />}
        <ToastContainer />
        <p className="font-poppins font-bold antialiased text-lg">
          Let's begin with basic details and get started
        </p>
        <div className="max-h-[75vh] md:h-auto lg:h-auto overflow-auto w-full lg:flex md:flex">
          <div className="lg:w-1/4 md:w-1/4 sm:1/4 bg-[#ECECEC] max-h-[70vh] overflow-y-auto overflow-x-hidden mt-2 rounded-md p-2 pb-4">
            <ClientInformation />
          </div>
          <div className="lg:w-3/4 md:w-3/4 sm:1/4 pl-2">
            <div className="mt-4">
              <ClaimInformation />
              <InsuredInformation />
            </div>
            <div className="flex mt-2 items-center">
              <span className="font-bold text-[#045373]">
                Is Interview needed to gather more information?
              </span>
              <Checkbox
                className="h-8 w-8 border-2 border-black"
                checked={insuredInfo.requiredOtherInfo}
                onChange={() =>
                  dispatch(
                    setInsuredInfo({
                      ...insuredInfo,
                      requiredOtherInfo: !insuredInfo.requiredOtherInfo,
                    })
                  )
                }
              />
            </div>

            <div className="flex">
              <div className="w-[70%]">
                {!insuredInfo.requiredOtherInfo && (
                  <FormInput
                    name={"Comments"}
                    label={"Comments"}
                    required={!insuredInfo.requiredOtherInfo ? true : false}
                    type={"text"}
                    className={
                      "border-2 border-[#CECECE] border-t-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent h-[34px]"
                    }
                    value={claimInfo.comments}
                    onChange={(e) =>
                      dispatch(
                        setClaimInfo({ ...claimInfo, comments: e.target.value })
                      )
                    }
                    disabled={!areRequiredFieldsFilled()}
                  />
                )}
              </div>
              <div className="flex lg:flex-row md:flex-row sm:flex-row  gap-2 py-4 px-4 content-center justify-end ">
                <CustomButton
                  className="w-full sm:w-auto rounded-lg pl-7 pr-7 pt-4 pb-4 !text-black border-none font-semibold hover:!text-black transition-transform transform active:scale-95 shadow-lg hover:shadow-xl"
                  label="Reset"
                  onClick={handleReset}
                />

                <CustomButton
                  className="w-full sm:w-auto bg-[#1f7fbb] rounded-lg pl-7 pr-7 pt-4 pb-4 !text-white border-none font-semibold transition-transform transform active:scale-95 shadow-lg hover:shadow-xl"
                  label="Save"
                  onClick={handleSave}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSideSection;
