import axios from "axios";

const axiosInstanceChatbot = axios.create({
  // baseURL: "https://claims-detail-service-gvctcaf6athtdyap.eastus2-01.azurewebsites.net/api/",
  // baseURL:'http://localhost:8087/api',
  // baseURL:"https://claims-service.azurewebsites.net/api/",
//   baseURL:"https://dev-claim-service-dverezamgbfbdqg3.eastus2-01.azurewebsites.net",
  // baseURL:"https://api.ampcusforensics.net/api",
  baseURL:"https://testforensicchatbot.azurewebsites.net",
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstanceChatbot;
