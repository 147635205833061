import { CameraIcon, FolderIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import { Button } from "@mui/material";
import { useRef } from "react";

export default function PopoverOption() {
  const inputFile: any = useRef();
  return (
    <Popover placement="top">
      <PopoverHandler>
        <Button className="flex justify-center w-12 h-12 bg-[#fff] rounded-full ">
          <PlusIcon className="w-7 h-7 self-center" />
        </Button>
      </PopoverHandler>
      <PopoverContent className="w-72">
        <List className="p-0">
          <div
            onClick={() => inputFile.current.click()}
            className="text-initial font-medium text-blue-gray-500"
          >
            <input
              ref={inputFile}
              type="file"
              id="file"
              style={{ display: "none" }}
            />
            <ListItem>
              <ListItemPrefix>
                <FolderIcon color="red" width={20} height={20} />
              </ListItemPrefix>
              Choose file
            </ListItem>
          </div>
          <a href="#" className="text-initial font-medium text-blue-gray-500">
            <ListItem>
              <ListItemPrefix>
                <CameraIcon color="red" width={20} height={20} />
              </ListItemPrefix>
              Capture from camera
            </ListItem>
          </a>
        </List>
      </PopoverContent>
    </Popover>
  );
}
