/* eslint-disable react-hooks/exhaustive-deps */
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import React, { useCallback, useEffect } from "react";
import {  useSelector } from "react-redux";
import { RootState } from "src/store";
import { resetClaimData, setClaimInfo, updateClaimNumberStatus } from "src/store/slices/claimSlice";
import { ClaimData } from "./ClaimData";

import { ASSIGNEE_LIST } from "src/utils/constant";
import {  checkClaimNumberPresent, getFileNumber } from "src/api/claimApi";
import { useAppDispatch } from "src/store/hooks";
import moment from "moment";
export type ClaimInfo = Pick<
  ClaimData,
  "fileNumber" | "claimNumber" | "assignee" | "dateOfLoss"
>;

const ClaimInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const claimInfo = useSelector((state: RootState) => state.claim.claimInfo);
  const {isUniqueClaimNumber} = useSelector((state: RootState) => state.claim);
  const STATUS_OPTIONS = ASSIGNEE_LIST;
  const claimInputs: {
    label: string;
    key: keyof ClaimInfo;
    isSelect?: boolean;
    isDate?: boolean;
    required: boolean;
    disabled?: boolean;
  }[] = [
    {
      label: "Ampcus File Number",
      key: "fileNumber",
      required: true,
      disabled: true,
    },
    { label: "Claim Number", key: "claimNumber", required: true },
    {
      label: "Ampcus Forensic Assignee",
      key: "assignee",
      isSelect: true,
      required: true,
    },
    { label: "Date of Loss", key: "dateOfLoss", isDate: true, required: true },
  ];
  useEffect(() => {
    dispatch(resetClaimData("ALL"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFileNumber(""));
  }, []);

  useEffect(() => {
    if (claimInfo?.claimNumber) {
    }
  }, [claimInfo]);

  const handleInputChange = useCallback(
    (key: keyof ClaimInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
    if(key==="claimNumber"){
        dispatch(updateClaimNumberStatus())
    }
      dispatch(setClaimInfo({ ...claimInfo, [key]: value }));
    },
    [dispatch, claimInfo]
  );

  const handleAssigneeChange = useCallback(
    (value?: any) => {
      let selectedItem = STATUS_OPTIONS.find(
        (option) => option.value === value
      );
      dispatch(
        setClaimInfo({
          ...claimInfo,
          assignee: selectedItem?.name,
          assigneeEmail: value,
        })
      );
    },
    [dispatch, claimInfo]
  );

  const getSelectedItem = useCallback(() => {
    let assigneeId = claimInfo.assigneeEmail || "";
    let selectedItem = STATUS_OPTIONS.find(
      (option) => option.value === assigneeId
    ) || { name: "", value: null };
    return selectedItem;
  }, [claimInfo.assigneeEmail]);

  const onblur=(key:string)=>{
    if(key==="claimNumber"){
        dispatch(checkClaimNumberPresent(claimInfo.claimNumber.trim()))
      }
  }
  return (
    <React.Fragment>
      <div>
        <span className="font-bold text-[#045373]">Claim Information</span>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-1">
        {claimInputs.map(
          ({ label, isSelect, isDate, key, required, disabled }) => (
            <div key={label} className=" flex flex-col gap-1">
              {isSelect ? (
                <FormSelect
                  name={label}
                  label={label}
                  required={required}
                  items={STATUS_OPTIONS}
                  className="p-0 pl-[16px] text-sm text-blue-gray-700 w-full h-[34px] border-2 rounded-md border-[#CECECE] bg-white focus:shadow-lg focus:shadow-sky-100 focus:border-1 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10"
                  selectedItem={getSelectedItem()}
                  onChange={handleAssigneeChange}
                />
              ) : (
                <>
                <FormInput
               
                  disabled={disabled}
                  name={label}
                  label={label}
                  required={required}
                  type={isDate ? "date" : "text"}
                  maxDateTime={moment(new Date()).format("YYYY-MM-DD")}
                  className={
                    isDate
                      ? "h-[34px] focus:border-t-transparent border-2 border-[#b0bec5] br-top bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent"
                      : "border-2 border-[#CECECE] border-t-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent h-[34px]"
                  }
                  value={claimInfo?.[key]}
                  onChange={handleInputChange(key)}
                  onBlur={()=>onblur(key)}
                />
                {(claimInfo.claimNumber && key==="claimNumber" && !isUniqueClaimNumber)&&
                <span className="text-red-600 text-xs -mt-2">Claim number is already used.</span>
                }
                </>
              )}
            </div>
          )
        )}
      </div>
    </React.Fragment>
  );
};

export default ClaimInformation;
