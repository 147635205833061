import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstanceQues from './axiosInstanceQues';

interface Questionair {
  setId: string;
}
export const callCategory = createAsyncThunk("callCategory", async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceQues.get('/questionnaire/categories');
      return response.data;

    } catch (error:any) {
        
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const callQuestions = createAsyncThunk("callQuestions", async (payload:Questionair, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceQues.get(`/questionnaire/questions/get-ui-questions/${payload.setId}`);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const saveAnswer = createAsyncThunk("saveAnswer", async (payload:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceQues.post(`/questionnaire/questions-answer/create`,payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  });