import {createSlice} from '@reduxjs/toolkit';
import { getClientList, updateClientById } from 'src/api/clientApi';
interface clientState{
    loading:Boolean,
    clients:any,
    error:any,
    updatedClientRes?:any
}
const initialState :clientState ={
    loading:false,
    clients:[],
    updatedClientRes:null,
    error:''
}
const clientSlice = createSlice({
    name: "Clients",
    initialState,
    reducers:{},
    extraReducers:(builder)=> {
        builder
        .addCase(getClientList.pending,(state)=>{
            state.loading= true
        })
        .addCase(getClientList.fulfilled,(state,action)=>{
            state.loading= false
            state.clients= action.payload
            state.error=''
        })
        .addCase(getClientList.rejected,(state,action)=>{
            state.loading= false
            state.clients= []
            state.error = action.error.message;
        })
        .addCase(updateClientById.pending,(state)=>{
            state.loading= true
            state.error=""
        })
        .addCase(updateClientById.fulfilled,(state,action)=>{
            state.loading= false
            state.updatedClientRes= action.payload
            state.error=''
        })
        .addCase(updateClientById.rejected,(state,action)=>{
            state.loading= false
            state.updatedClientRes= null
            state.error = action.error.message;
        })   
    }
});
export default clientSlice.reducer