// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-calendar .react-calendar__month-view__weekdays__weekday {
  font-weight: normal;
  text-decoration: none; /* Removes underline */
}

.custom-calendar .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none; /* Ensure abbreviation has no underline */
}

.event-dot {
  width: 6px;
  height: 6px;
  background-color: purple;
  border-radius: 50%;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/modules/talkWithBot/CalendarWithEvents.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qBAAqB,EAAE,sBAAsB;AAC/C;;AAEA;EACE,qBAAqB,EAAE,yCAAyC;AAClE;;AAEA;EACE,UAAU;EACV,WAAW;EACX,wBAAwB;EACxB,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".custom-calendar .react-calendar__month-view__weekdays__weekday {\n  font-weight: normal;\n  text-decoration: none; /* Removes underline */\n}\n\n.custom-calendar .react-calendar__month-view__weekdays__weekday abbr {\n  text-decoration: none; /* Ensure abbreviation has no underline */\n}\n\n.event-dot {\n  width: 6px;\n  height: 6px;\n  background-color: purple;\n  border-radius: 50%;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
