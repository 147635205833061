import { BrowserRouter, Routes, Route } from "react-router-dom";
import StartInterviewForAdjuster from "../../path/startInterviewForAdjuster";
import DefaultLayout from "../../layout/DefaultLayout";
import Dashboard from "../../modules/Dashboard/Dashboard";
import ClaimDetails from "../../modules/Dashboard/Claim-Details/ClaimDetails";
import ClaimDocuments from "../../modules/Dashboard/Claim-Documents/index";

// import AwsChatbot from "../../modules/aws-lex/AwsChatbot";
import StartNewClaim from "../../path/startNewClaim";
import queryString from "query-string";
import Documents from "../../modules/Documents";
import Login from "../../modules/auth/Login";
import Auth from "src/modules/auth/ValidateAuth";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OCRDocuments from "src/modules/Dashboard/OCR-Documents";
interface userinfo{
  user:any
}

const DefinePath= () => {
  const queries: any = queryString.parse(window.location.search);
  const {user}=useAppSelector((state:RootState)=>state.user)
  const[userInfo,setUserInfo ]= useState<userinfo>();

  useEffect(()=>{
    let info:any = localStorage.getItem("UserInfo") ?localStorage.getItem("UserInfo"):null 
    setUserInfo({...userInfo,user:info?JSON.parse(JSON.parse(info)):{}})
    if(JSON.parse(JSON.parse(info))?.id && window?.location?.pathname==="/" ){
      window.location.href ='/dashboard'
    }

  },[user])
  return (
    <BrowserRouter>
      {!queries.data ? (
        <Routes>
          {userInfo?.user?.id?
          <Route element={<DefaultLayout hideSidebar={false} hideHeader={false} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="claim-details" element={<ClaimDetails />} />
            <Route path="claim-details/claim-documents" element={<ClaimDocuments />} />
            <Route path="claim-details/claim-documents/ocr-documents" element={<OCRDocuments />} />
            <Route path="/validate" element={<Auth />} />
            <Route path="/startNewClaim" element={<StartNewClaim />} />
            <Route path="/documents" element={<Documents />} />
          </Route>
          :
          <Route element={<DefaultLayout hideSidebar={true} hideHeader={true}/>}>
          <Route path="/" element={<Login />} />
            </Route>
             } 
        </Routes>
      ) : (
        <Routes>
          <Route element={<DefaultLayout hideSidebar={true} hideHeader={false} />}>
            <Route
              path="/schedule-interview/:data"
              element={<StartInterviewForAdjuster />}
            />
          </Route>
        </Routes>
      )}
    </BrowserRouter>
  );
};
export default DefinePath;
