/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Thankyou from "../../assets/images/thankyou.png";
const SuccessMessage = () => {
  return (
    <div className='flex flex-col items-center'>
        <img src={Thankyou} className={"w-[400px] h-[300px]"}/>
        <label className='text-[#0774D9] font-medium text-sm'>THANK YOU VERY MUCH</label>
        <label className='text-[#000] font-semibold text-sm mt-2 leading-2 text-center p-5'>Your information will be sent to an accountant and they will be sending a request for information to you detailing all the documentation needed to evaluate your claim via an email.</label>
      
    </div>
  )
}

export default SuccessMessage
