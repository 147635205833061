export interface InformationProps {
  id?: any;
  label: string;
  description?: string;
  type: string;
  value: string;
  options?: any;
  optionalType?: any;
}

const DemographicData: Array<InformationProps> = [
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Business Name",
    type: "text",
    value: "",
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Business Address",
    type: "text",
    value: "",
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Email Address",
    type: "text",
    value: "",
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Phone Number",
    type: "email",
    value: "",
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Hours of Operation",
    description: "Where did the incident occur (specific address or location)?",
    type: "customRadio",
    value: "",
    options:["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Are you regular or seasonal business?",
    type: "radio",
    value: "",
    options:["Regular","Seasonal"]
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Months of Operation",
    type: "customRadio",
    value: "",
    options:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
    
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Please tell us about the business and how it generates revenue.",
    type: "text",
    value: "",
  },
];

const LossInformation: Array<InformationProps> = [
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Business' operational status during loss",
    type: "checkbox",
    value: "",
    options: ["Fully Closed","Partially Closed"],
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Currently open or closed",
    type: "checkbox",
    value: "",
    options: ["Open","Closed"],
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Business Restart date",
    type: "date",
    value: "",
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Extra expenses incurred",
    type: "checkbox",
    value: "",
    options: ["Yes","No"],
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "What are the extra expenses you incurred associated to your operations post the incident?",
    type: "text",
    value: "",
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "How is or was your business operations impacted by the loss?",
    type: "text",
    value: "",
  }
];
const coverage: Array<InformationProps> = [
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Does your business prepare financial statements on a monthly or annual basis?",
    type: "checkbox",
    value: "",
    options: ["Monthly","Annual","No Financial Statement"],
  }
];
const FinancialStatement: Array<InformationProps> = [
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Do you have standard Financial statement?",
    description: "Tax return, Profit & Loss statement",
    type: "radio",
    value: "",
    options: ["Yes", "No"],
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label: "Can you provide us the latest Financial statement?",
    description: "Give the latest information",
    type: "radio",
    value: "",
    options: ["Yes", "No"],
  },
  {
    id: (Math.random() * 1e32).toString(36),
    label:
      "What would be the preferred method to provide us the financial statement?",
    description: "",
    type: "select",
    value: "",
    options: [],
    optionalType: "upload",
  },
];
export { DemographicData, LossInformation, coverage, FinancialStatement };
