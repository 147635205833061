import { configureStore } from "@reduxjs/toolkit";
import botReducer from "./slices/botSlice";
import dashboardReducer from "./slices/dashboardSlice";
import claimReducer from "./slices/claimSlice";
import clientReducer from "./slices/clientSlice"
import interviewReducer from "./slices/interviewSlice";
import questionnaireReducer from "./slices/questionnaire";
import documentsReducer from "./slices/documentsSlice"
import userReducer from "./slices/user"
import chatbotReducer from "./slices/chatBotSlice"
export const store = configureStore({
  reducer: {
    talkBot: botReducer,
    dashboardClaims: dashboardReducer,
    claim: claimReducer,
    client :clientReducer,
    interview:interviewReducer,
    questionnair:questionnaireReducer,
    documents:documentsReducer,
    user:userReducer,
    chatbot:chatbotReducer
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

