import React, { useEffect, useState } from "react";
import { Interview_Tabs, timeZones } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import {
  clearSelection,
  updateDateTime,
  updateTimeZone,
} from "../../store/slices/botSlice";
import { useAppSelector } from "src/store/hooks";
import CalendarWithEvents from "./CalendarWithEvents";
import TalkWithBotForm from "./TalkWithBotForm";
import SuccessPath from "src/Features/successPath";
import { getScheduleList } from "src/api/interview";
interface props {
  type: string;
  siteData: any;
  onClickActionButton: ({
    data,
    actionType,
    moduleType,
  }: InterViewProps) => void;
}

const TalkWithBot = ({ type, onClickActionButton, siteData }: props) => {
  const formValue = useAppSelector((state: RootState) => state.talkBot);
  const { 
    getScheduleListData
  } = useAppSelector((state: RootState) => state.interview);
  const dispatch = useDispatch();
  const dispatchForScheduleList = useDispatch<AppDispatch>();
  const [successMessage, setSuccesMessage] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  // const [events] = useState<{ [key: string]: string[] }>({
  //   [getFormattedDate(today)]: ["Session call", "Interview Call"],
  //   [getFormattedDate(tomorrow)]: [
  //     "Session call with Bot",
  //     "Interview Call with accountant",
  //   ],
  // });

 
  const handleDateTimeChange = (value: string) => {
    dispatch(updateDateTime(value));
  };

  const handleTimeZoneChange = (value: string) => {
    dispatch(updateTimeZone(value));
  };

  const handleClearSelection = () => {
    dispatch(clearSelection());
    onClickActionButton({
      data: null,
      actionType: "RESET",
      moduleType: "TALK_WITH_BOAT",
    });
  };
  const callScheduleList = () => {
    const { email,claimNumber } = siteData;
    const payload = {
      insuredEmail:email,
      claimsId:claimNumber
    };
    dispatchForScheduleList(getScheduleList(payload));
  };

  useEffect(()=>{
    if(siteData?.email && siteData?.claimId){
    callScheduleList()
    }
  },[siteData])

  const handleSubmit = () => {
    onClickActionButton({
      data: { dateTime: formValue.dateTime, timeZone: formValue.timeZone },
      actionType: "SUBMIT",
      moduleType:
        type === Interview_Tabs.ACCOUNTANT_CALL
          ? "TALK_WITH_ACCOUNTANT"
          : "TALK_WITH_BOAT",
    });
    // setSuccesMessage(true);
  };

  
  return !successMessage ? (
    <div className="bg-[#EEEEEE] h-auto rounded-sm m-auto w-[100%] mt-4 py-2 flex justify-center">
      <div className="flex flex-col w-[70%]">
        <h2 className="text-[#009BDB] whitespace-nowrap font-poppins text-center text-base font-normal h-auto mb-2">
        When would you like to schedule the call?
        </h2>
      <div className="lg:flex md:flex sm:grid lg:justify-between md:justify-between text-sm pl-4">
        <p className="whitespace-nowrap text-sm font-medium">
          Insured Business Name: &nbsp;
          <span className="text-[#1F7FBB] font-semibold">{siteData?.buisnessName}</span>
       </p>

          <p className="whitespace-nowrap text-sm font-medium">       Claim Number: &nbsp;
            <span className="text-[#1F7FBB] font-semibold">#{siteData?.claimNumber}</span>
          </p>
        </div>

        {type === "Talk with a Bot" && (
          <h2 className="text-[#000]-500 font-poppins text-center text-base font-medium h-auto mt-6">
            Please call a bot at
            <span className="text-[#1F7FBB] font-semibold"> +1 (888) 470-1067</span>.
          </h2>
        )}

        {type !== "Talk with a Bot" && (
          <>
            <TalkWithBotForm
              formValue={formValue}
              timeZones={timeZones}
              onDateTimeChange={handleDateTimeChange}
              onTimeZoneChange={handleTimeZoneChange}
              onSubmit={handleSubmit}
              onClear={handleClearSelection}
            />
            <CalendarWithEvents
              selectedDate={selectedDate}
              events={getScheduleListData}
              onDateChange={setSelectedDate}
             
            />
          </>
        )}
      </div>
    </div>
  ) : (
    <div>
      <SuccessPath
        message="Thank you
Your call has been scheduled successfully!"
        setShow={setSuccesMessage}
      />
    </div>
  );
};
const getFormattedDate = (date: Date) => {
  return date.toISOString().split("T")[0]; // Converts to YYYY-MM-DD format
};

export default TalkWithBot;
