import React from "react";

const ClaimDetailsLogo = ({ active }: { active: boolean }) => {
  return (
    <svg
      width="31"
      height="41"
      viewBox="0 0 30 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="30" height="38" fill="url(#pattern0_236_754)" />
      <defs>
        <pattern
          id="pattern0_236_754"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_236_754"
            transform="matrix(0.01 0 0 0.00789474 0 0.105263)"
          />
        </pattern>
        <image
          style={{ filter: active ? "" : "brightness(0) invert(1)" }}
          id="image0_236_754"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE7UlEQVR4nO2dT4gcRRTGJ2oUlaCCGNedqdcblRVFJS6ioAQPClEjOWgQL4qKiwgBY1w2Zqfr6UEQRQRBxYuCeHGJIKIm/d6sixCCh1XxIl7EP4gXIYfgH1A3I7UThsxMb2/vdPV0b/r74B27arp+89Wrqp7pV6sVKHPgk0smuHWDCaMpXxHMLVCR97QhFXC0w7B8Tqz/EWvbdxiWn8jK/qnppc1F32vpRVb3kdXlPEBQPxirX0zOHN1S9D2XVkGou8nKyVHAoG7IoaLvu5xiPous/DBaGLoSboos+vZLJ2K5NWauP0FWXzFWZr0Ea9OwfD3Yj75Z9P2XTsT62OAcL0/472fxYmPl7/5c4rufDS9ieTpmOrkjj76M1R/7gHyTRz8bWgBSMgFIyQQgJROAlEwA4kHEi5e7pSmxvG6sfkhWNFWwvJ9ylbWUuk2bPmKWvQOrrAbrw+nb008N6ztkZc4dYNZq7U21UWq8KVcT6/ywZ05u2ZkSyEjCxAAh1ueHbo/le8OtB0YChqw8RCx/ZRyAMxoIdUMObd0fXZgfDNZnPA1ARYCoO5b5sr7v2Pn+YYStu309n6gSEOo4ZSBnZtK1PH+uz5PY6gHRdiNs3eUNCLE86XkAKgfEsBzzBuTU41QA4axjcDjIDOOqvZ+dR1b+BRDN/oW08mh2d8xFEzlMEZWbsqjzfIczA6mHejOAqC8gb+TyaBUO0WGd+BaAcJmmLABpAwiSehsO4Y2yysKU1QYQTFltOIQxZWWdSyu6U1fsQwhA4BCCQxRTFnKIIocQkjpOewmrLCx7E4XnIYp9CDaGCYJDFA6BQxIEhygcAockCA5ROAQOSRAconAIHJIgOEThEDgkQXCIwiFwSILgEIVD4JAEwSEKh8AhCYJDFA6BQxIEhygcAockCA5ROAQOSRAconAIHJIgOETL5ZCgeeTGHP7aVdW/tL2WGcg2ji4DEPUDhOVZX4VWfodDNDuQUO/1Vd7hXQDRbECs/jnGH1/gB0gY3QYgmhGIvO0FRhcKy0cek1vVkvofDZYrvAKZeK611Vj9GUB0vc446d4GXstD9aZeTyy/wiGaNm8suwKWtTxlDh4eI9YWpixdC8hvjTC6rzYquVePr5Rp6Cv9UPUcYqx8S6wHcn0Jf5Jcx4ZbNxnWXcbKnjRBLPfElcBIe73xHHGvAW80F65L3Uao9zdYb/eeuCEIgiAIOtPlTnxZdxrWaYTmNwa29bhbrbrqd4k8XHGrovYIlQy7Uv2uFYTRLQMwGnMLVxb+AasaVpeN1ZkeIOO8WC/8g1U8jNW9PVCMlVeL/lBVDsPyjzvJ6IESNBcmDeudCM1tDIJQdxPrS3GFO91T20wLM2h4uWQeU/freI0Xz8nQLJRFZPWDXKq8QcPJWH1xAEhTtw/ZHJRVxCL9QOoHW+OZG4bWr8DqI4MrLf2lWw58anppc2D1QWNl9vRwhRInZ45uWbODuQUi1qf6r0dI33jqC3HO6IS8fGo425uM1Wj1NbJ+l/QDsM5yWU4UvZanjR3Hx3jx0pUBdRuStTcuumtdyQnRXscYuJLpO3t/ZdI57Fr1ooCjHasDkVkA0KGd0QOjO6gsNgHKe91kE6Nts3oRWfkKUDQ1CJfAXc7oTlNxcmf0JoymTo/UG5U982cTH7mm/3pE1DueTd3e+eXK6l/w/wGpuAVHAFknOQAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};

export default ClaimDetailsLogo;
