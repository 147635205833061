import DateTimePicker from "../../components/DateTimePicker";
import FormSelect from "../../components/FormSelect";
import CustomButton from "../../components/CustomButton";
import { cx } from "class-variance-authority";
import React from "react";

interface TalkWithBotFormProps {
  formValue: any;
  timeZones: any[];
  onDateTimeChange: (value: string) => void;
  onTimeZoneChange: (value: string) => void;
  onSubmit: () => void;
  onClear: () => void;
}

const TalkWithBotForm: React.FC<TalkWithBotFormProps> = ({
  formValue,
  timeZones,
  onDateTimeChange,
  onTimeZoneChange,
  onSubmit,
  onClear,
}) => {
  return (
    <>
      <form className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-2 py-4 px-4 w-full ">
        <div className="flex flex-col">
          <label className="text-[#000]-500 font-poppins text-sm font-medium mb-1 ">
            Select Date & Time
          </label>
          <DateTimePicker
            value={formValue.dateTime}
            onChange={onDateTimeChange}
            minDateTime={new Date()}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[#000]-500 font-poppins text-sm font-medium mb-1 ">
            Select Time Zone
          </label>
          <FormSelect
            items={timeZones}
            className={"h-9 rounded-lg w-full text-sm"}
            onChange={onTimeZoneChange}
            selectedItem={formValue.timeZone}
          />
        </div>
      </form>
      <div className="flex lg:flex-row md:flex-row sm:flex-row gap-2 py-4 px-4 justify-center">
        <CustomButton
          className={cx(
            "bg-[#1F7FBB]",
            "border-[#1F7FBB]",
            "justify-center",
            "shadow-md shadow-[#1F7FBB]",
            "w-[150px]"
          )}
          label="Submit"
          onClick={onSubmit}
        />
    
          <CustomButton
              className="w-[150px] bg-[#fff] shadow-md shadow-[#fff] sm:w-auto rounded-lg pl-7 pr-7 pt-4 pb-4 !text-black border-none font-semibold hover:!text-black transition-transform transform active:scale-95 shadow-xl hover:shadow-xl"
              label="Reset"
              onClick={onClear}
            />
      </div>
    </>
  );
};

export default TalkWithBotForm;
