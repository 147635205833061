import * as React from "react";
import Tabs from "../../modules/InterviewForAdjuster/Tabs";
import { useState } from "react";
import { Interview_Tabs } from "../../utils/constant";
import AwsChatbot from "../../modules/aws-lex/AwsChatbot";
import TalkWithBot from "../../modules/talkWithBot";
import Interview from "../../modules/InterviewForAdjuster/interview/index";
import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { RootState } from "src/store";
import { toast } from "react-toastify";
import Loader from "src/components/loader";
import SuccessPath from "src/Features/successPath";
import { clearSelection } from "src/store/slices/botSlice";
import { attemptInterview, scheduleInterview } from "src/api/interview";
import { saveTranscriptDocumets } from "src/api/documentApi";

const StartInterviewForAdjuster = (props?: any) => {
  const [siteData, setSiteData] = useState<any>();
  const [readyToRender, setReadyToRender] = useState<string>("In Progress");
  const { data } = useParams<string>();
  const dispatch = useAppDispatch<any>();
  const [successMessage, setSuccesMessage] = useState<boolean>(false);
  const { error, message, loading } = useAppSelector(
    (state: RootState) => state.interview
  );
  const {
    loading: docLoading,
    message: docMessage,
    error: docError,
  } = useAppSelector((state: RootState) => state.documents);
  React.useEffect(() => {
    if (data && !siteData) {
      decrypt1();
    } else {
      setReadyToRender("URL is not valid");
    }
  }, [data]);
  React.useEffect(() => {
    if (siteData && siteData.authToken) {
      attemptInterView(siteData);
      setReadyToRender("Completed");
    } else {
      setReadyToRender("URL information is not valid");
    }
  }, [siteData]);

  const attemptInterView = (data: urlDecodedDataProps) => {
    const { authToken, claimNumber, tempPassword } = data;
    const payload: attemptInterview = {
      authToken: authToken,
      claimsId: claimNumber,
      tempPassword: tempPassword,
    };
    dispatch(attemptInterview(payload));
  };

  React.useEffect(() => {
    if (error && message) {
      toast.error(message);
    } else if (!error && message) {
      setSuccesMessage(true);
      toast.success(message);
    }
  }, [message, error]);

  React.useEffect(() => {
    if (docError && docMessage) {
      toast.error(docMessage);
    } else if (!docError && docMessage) {
      setSuccesMessage(true);
      toast.success(docMessage);
    }
  }, [docMessage, docError]);

  const decrypt1 = () => {
    try {
      var finalData = data ? decodeURIComponent(data) : "";
      var base64EncodedKeyFromJava = "QUJDREVGR0hJSktMTU5PUA==";
      var keyForCryptoJS = CryptoJS.enc.Base64.parse(base64EncodedKeyFromJava);
      var decodeBase64 = CryptoJS.enc.Base64.parse(finalData);

      var decryptedData = CryptoJS.AES.decrypt(
        {
          ciphertext: decodeBase64,
        },
        keyForCryptoJS,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);

      setSiteData(JSON.parse(decryptedText));
    } catch (e) {
      setReadyToRender("URL information is not valid");
    }
  };
  const [activeTab, setActiveTab] = useState<any>(Interview_Tabs.TALK_WITH_BOT);

  const onClickActionButton = ({
    data,
    actionType,
    moduleType,
  }: InterViewProps) => {
    if (actionType === "SUBMIT") {
      const { dateTime, timeZone } = data;
      let errorFileds = "";
      if (dateTime?.trim()?.length === 0) {
        errorFileds += " Date & Time,";
      }
      if (timeZone?.trim()?.length === 0) {
        errorFileds += " Time Zone";
      }
      if (errorFileds) {
        toast.error("Please select " + errorFileds);
        return;
      }
    }

    if (moduleType === "TALK_WITH_BOAT") {
      if (actionType === "SUBMIT") {
        callScheduleApi({ data, moduleType });
      }
    } else if (moduleType === "TALK_WITH_ACCOUNTANT") {
      if (actionType === "SUBMIT") {
        callScheduleApi({ data, moduleType });
      }
    }
  };

  const getSchudalFormattedDate = (date: Date) => {
    let hours: any = date.getHours();
    hours = ("0" + hours).slice(-2);
    let minutes: any = date.getMinutes();
    minutes = ("0" + minutes).slice(-2);
    let month: any = date.getMonth();
    month = ("0" + month).slice(-2);
    let date1: any = date.getDate();
    date1 = ("0" + date1).slice(-2);

    let finalDate =
      date.getFullYear() +
      "-" +
      month +
      "-" +
      date1 +
      "T" +
      hours +
      ":" +
      minutes +
      ":" +
      "00";

    return finalDate;
  };

  const callScheduleApi = ({ data, moduleType }: InterViewProps) => {
    const { dateTime, timeZone } = data;
    if (!siteData) return;
    const {
      name,
      title,
      tempPassword,
      authToken,
      buisnessName,
      claimNumber,
      email,
    }: urlDecodedDataProps = siteData;

    // 2024-10-31T14:00:00
    const start_date = new Date(dateTime);
    const end_date = new Date(start_date.getTime() + 45 * 60000);
    const finalStartDate = getSchudalFormattedDate(start_date);
    const finalEndDate = getSchudalFormattedDate(end_date);
    const payload: InterViewSchedule = {
      interviewType: moduleType,
      insuredEmail: email,
      // interviewScheduledWithEmail: "string",
      // insuredPhone: "string",
      // countryCode: "string",
      insuredName: name,
      // ccEmailId: "string",
      interviewStartTime: finalStartDate,
      interviewEndTime: finalEndDate,
      timezone: timeZone,
      interviewStatus: "DRAFT",
      authToken: authToken,
      // meetingId: "string",
      // meetingPasscode: "string",
      // meetingLink: "string",
      claimsId: claimNumber,
    };
    dispatch(scheduleInterview(payload));
  };

  const _onSessionEnd = (sessionId: string) => {
    if (!siteData) return;
    const { authToken, claimId }: urlDecodedDataProps = siteData;
    const payload: any = {
      authToken: authToken,
      claimId: claimId,
      downloadUrl: sessionId + "_session_attributes.xlsx",
      cloud: "AWS",
      type: "Transcript",
      filename: sessionId + "_session_attributes.xlsx",
    };
    dispatch(saveTranscriptDocumets(payload));
  };

  React.useEffect(() => {
    setSuccesMessage(false);
    dispatch(clearSelection());
  }, [activeTab]);

  if (readyToRender === "Completed") {
    return (
      <div className="container mx-auto">
        {(loading || docLoading) && <Loader />}
        <Tabs setActiveTab={setActiveTab} activeTab={activeTab} {...props} />
        {Interview_Tabs.CHAT_WITH_BOT === activeTab &&
          (!successMessage ? (
            <AwsChatbot onSessionEnd={_onSessionEnd} siteData={siteData} />
          ) : (
            <SuccessPath
              message="Your information will be sent to an accountant and they will be sending a request for information to you detailing all the documentation needed to evaluate your claim via an email."
              setShow={successMessage}
            />
          ))}
        {Interview_Tabs.TALK_WITH_BOT === activeTab && (
          <>
            {!successMessage ? (
              <TalkWithBot
                onClickActionButton={onClickActionButton}
                type={Interview_Tabs.TALK_WITH_BOT}
                siteData={siteData}
                {...props}
              />
            ) : (
              <SuccessPath
                message="Your details has been recorded and team will revert back to you with additional information needed."
                setShow={successMessage}
              />
            )}
          </>
        )}
        {Interview_Tabs.QUESTIONNAIRE === activeTab && (
          <Interview
            type={Interview_Tabs.QUESTIONNAIRE}
            {...props}
            siteData={siteData}
          />
        )}

        {Interview_Tabs.ACCOUNTANT_CALL === activeTab && (
          <React.Fragment>
            {!successMessage ? (
              <TalkWithBot
                onClickActionButton={onClickActionButton}
                type={Interview_Tabs.ACCOUNTANT_CALL}
                siteData={siteData}
                {...props}
              />
            ) : (
              <SuccessPath
                message="Your information will be sent to an accountant and they will be sending a request for information to you detailing all the documentation needed to evaluate your claim via an email or a phone call"
                setShow={successMessage}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  } else {
    if (readyToRender === "In Progress") {
    } else {
      return <div className="container mx-auto">{readyToRender}</div>;
    }
  }
};
export default StartInterviewForAdjuster;
