import {createSlice} from '@reduxjs/toolkit';
import { callCategory, callQuestions } from 'src/api/questionair';
import { saveAnswer } from '../../api/questionair';

interface QuestionerState{
    loading:boolean,
    error:boolean,
    message:any,
    categoryRes:any,
    questionsRes:any,
    saveAnswerRes:any
}
const initialState :QuestionerState ={
    loading:false,
    error:false,
    message:"",
    categoryRes:null,
    questionsRes:null,
    saveAnswerRes:null
}
const questionnaireSlice = createSlice({
    name: "Questioner",
    initialState,
    reducers:{},
    extraReducers:(builder)=> {
        builder
        .addCase(callCategory.pending,(state)=>{
            state.loading= true
            state.categoryRes= null
            state.error=false
        })
        .addCase(callCategory.fulfilled,(state,action)=>{
            state.loading= false
            state.categoryRes= action.payload
            state.error=false
            
        })
        .addCase(callCategory.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.error=true;
            state.categoryRes= null
        })
        .addCase(callQuestions.pending,(state)=>{
            state.loading= true
            state.error=false
            state.questionsRes=null
        })
        .addCase(callQuestions.fulfilled,(state,action)=>{
            state.loading= false
            state.error=false
            state.message=action.payload?.message
            state.questionsRes=action.payload
        })
        .addCase(callQuestions.rejected, (state, action) => {
            state.loading = false;
            state.error=true
            state.message = action.payload;
            state.questionsRes=null
        })
        .addCase(saveAnswer.pending,(state)=>{
            state.loading= true
            state.error=false
            state.saveAnswerRes=null
        })
        .addCase(saveAnswer.fulfilled,(state,action)=>{
            state.loading= false
            state.error=false
            state.message=action.payload?.message
            state.saveAnswerRes=action.payload
        })
        .addCase(saveAnswer.rejected, (state, action) => {
            state.loading = false;
            state.error=true
            state.message = action.payload;
            state.saveAnswerRes=null
        })
    }
});
export default questionnaireSlice.reducer