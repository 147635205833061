import { Checkbox } from "@material-tailwind/react";
import Claim from "../Claim";
import CustomButton from "../../../components/CustomButton";
import {
  updateClaimByClaimId,
} from "src/api/claimApi";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { RootState } from "src/store";
import { toast } from "react-toastify";
import { setClaimInfo, setInsuredInfo } from "src/store/slices/claimSlice";
import FormInput from "src/components/FormInput";
import { useNavigate } from "react-router-dom";

interface FooterProps {
  claim: Claim | null;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
}

const Footer: React.FC<FooterProps> = ({
  claim,
  isEditMode,
  setIsEditMode,
}) => {
  const {
    claimInfo,
    insuredInfo,
    claimDetails,
    isUniqueClaimNumber,
  } = useAppSelector((state: RootState) => state.claim);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const _onClickCheckBox = () => {
    dispatch(
      setInsuredInfo({
        ...insuredInfo,
        requiredOtherInfo: !insuredInfo?.requiredOtherInfo,
      })
    );
  };

  const onUpdate = async () => {
    const {
      claimNumber,
      fileNumber,
      assigneeEmail,
      dateOfLoss,
      status,
      comments,
      assignee,
    } = claimInfo;
    const {
      insuredFirstName,
      insuredLastName,
      insuredTitle,
      insuredBusinessName,
      insuredPostalAddress,
      insuredPhoneNumber,
      insuredEmail,
    } = insuredInfo;

    const payload = {
      claimId: claimDetails?.id,
      fileNumber: fileNumber,
      claimNumber: claimNumber,
      assignee: assignee,
      assigneeEmail: assigneeEmail,
      dateOfLoss: dateOfLoss,
      insuredFirstName: insuredFirstName ,
      insuredLastName:insuredLastName,
      
      insuredTitle: insuredTitle,
      insuredBusinessName: insuredBusinessName,
      insuredPostalAddress: insuredPostalAddress,
      insuredPhoneNumber: insuredPhoneNumber,
      insuredEmail: insuredEmail,
      clientId: claimDetails?.clientDetails?.id,
      requiredOtherInfo: insuredInfo?.requiredOtherInfo,
      comments: comments,
      status: status,
    };

    if (
      !(
        claimNumber &&
        fileNumber &&
        assigneeEmail &&
        dateOfLoss &&
        status &&
        assignee &&
        insuredFirstName &&
        insuredLastName&&
        insuredBusinessName &&
        insuredPostalAddress &&
        insuredPhoneNumber &&
        insuredEmail
      )
    ) {
      toast.warn("Fill all mandatory fields before update.");
      return true;
    }


    // await dispatch(checkClaimNumberPresent(claimNumber));
    if (!isUniqueClaimNumber && claimDetails.claimNumber !== claimNumber) {
   
      return;
    }
    dispatch(updateClaimByClaimId(payload)).then((res: any) => {
      if (res?.payload?.statusCode === 200) {
        toast(res?.payload?.message);
        setIsEditMode(false);
      }
      if (res?.payload?.status === 500) {
        toast.error(res?.payload?.message);
      }
    });
  };


  const onReset = () => {
    toast.error("🦄 Wow so easy!", { autoClose: 1200 });
  };
  return (
    <>
      <div className="flex mt-10 items-center">
        <span className="font-bold text-[#045373]">
          Is Interview needed to gather more information?
        </span>
        <Checkbox
          disabled={!isEditMode}
          onClick={_onClickCheckBox}
          checked={insuredInfo?.requiredOtherInfo}
          className="h-8 w-8 border-2 border-black"
        />
      </div>
      <div className="w-[70%]">
        {!insuredInfo?.requiredOtherInfo &&
        isEditMode ?(
          <FormInput
            name={"Comments"}
            label={"Comments"}
            required={!insuredInfo.requiredOtherInfo ? true : false}
            type={"text"}
            className={
              "border-2 border-[#CECECE] border-t-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent h-[34px]"
            }
            value={claimInfo.comments}
            onChange={(e) =>
              dispatch(setClaimInfo({ ...claimInfo, comments: e.target.value }))
            }
          />
        ):
      claimInfo?.comments &&  <div className="grid">
        <span className="font-medium text-sm">{"Comments"}</span>
       <span className="font-medium whitespace-nowrap text-sm text-gray-700 mb-3">{claimInfo.comments}</span>
       </div>
        }
      </div>
      <div className="flex flex-col sm:flex-row gap-3 mt-4 justify-between items-center">
        {isEditMode && (
          <div className="flex flex-col sm:flex-row gap-3">
            <CustomButton
              className="w-full sm:w-auto rounded-lg pl-7 pr-7 pt-4 pb-4 !text-black border-none font-semibold hover:!text-black transition-transform transform active:scale-95 shadow-lg hover:shadow-xl"
              label="Reset"
              onClick={onReset}
            />
            <CustomButton
              className="w-full sm:w-auto bg-[#1f7fbb] rounded-lg pl-7 pr-7 pt-4 pb-4 !text-white border-none font-semibold transition-transform transform active:scale-95 shadow-lg hover:shadow-xl"
              label="Update"
              onClick={onUpdate}
            />
          </div>
        )}
        <div
          className={`flex items-center bg-[#0774d9] cursor-pointer rounded-2xl pl-4 pr-6 py-3 ${isEditMode ? "" : "ml-auto"}`}
          onClick={() => {
            navigate(`/claim-details/claim-documents`, { state: { claim } });
          }}
        >
          <img
            src={"/images/claimFolder.png"}
            className="w-12 h-8 pr-5"
            alt="Go to Documents"
          />
          <span className="text-white">Go to Documents</span>
        </div>
      </div>
    </>
  );
};

export default Footer;
