import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CalendarWithEvents.css";
import CardWithMenu from "./CardWithMenu";
import moment from "moment";
import Index from "../InterviewForAdjuster/interview";

interface CalendarWithEventsProps {
    selectedDate: Date | null;
    events: any;
    onDateChange: (date: any | any[]) => void;
}

const formatDate = (date: Date | null) => {
    if (!date) return "";
    return date.toLocaleDateString("en-GB", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric"
    });
};

const formatDateForEvents = (date: Date | null) => {
    return date ? moment(moment(date).toISOString()).format("YYYY-MM-DD"):""
};

const CalendarWithEvents: React.FC<CalendarWithEventsProps> = ({
    selectedDate,
    events,
    onDateChange,
}) => {

    const tileContent = ({ date }: { date: Date }) => {
        const dateString = formatDateForEvents(date);
        return events?.length>0 && events?.map((eve:any)=>( eve[dateString]?.length ? (
            <div className="event-dot"></div>
        ):null)) ;
    };
    return (
        <div className="flex border border-gray-200 rounded-lg overflow-hidden mt-4">
            <div className="w-1/2 p-4 bg-white">
                <Calendar
                    onChange={(ee)=>onDateChange(ee)}
                    value={selectedDate}
                    view="month"
                    calendarType="gregory"
                    className="border-0 custom-calendar"
                    formatShortWeekday={(locale:any, date:any) =>
                        date.toLocaleDateString(locale, { weekday: "narrow" })
                    }
                    tileContent={tileContent}
                    prev2Label={null}
                    next2Label={null}
                    prevLabel="◀"
                    nextLabel="▶"
                />
            </div>
            <div className="w-1/2 p-4 bg-white border-l border-gray-200">
                <h3 className="font-semibold text-lg mb-2 font-poppins">
                    Events on {formatDate(selectedDate)}
                </h3>
           
                <ul>
                    {events?.length>0 &&
                    events?.map((eve:any)=>(
                        eve[formatDateForEvents(selectedDate)]?.length ? (
                            eve[formatDateForEvents(selectedDate)].map((item:any, index :number) => (
                                <CardWithMenu item={item} selectedDate={selectedDate} key={index}/>
                          
                        ))):
                        (
                           <p className="text-sm text-gray-500">No events for this day.</p>
                       )
                    
                    ))
                }
                </ul>
            </div>
        </div>
    )
};

export default CalendarWithEvents;
