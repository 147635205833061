import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import InvoicesColumns from "./InvoicesColumns";
import { Box } from "@mui/material";

const staticPolicies = [
    { id: 1, policyTitle: "Health Policy", policyNumber: "HP-001", effectiveDate: new Date("2023-01-01").toLocaleDateString(), reviewDate: new Date("2023-12-31").toLocaleDateString(), action: "view" },
    { id: 2, policyTitle: "Safety Policy", policyNumber: "SP-002", effectiveDate: new Date("2022-06-15").toLocaleDateString(), reviewDate: new Date("2023-06-15").toLocaleDateString(), action: "edit" },
    { id: 3, policyTitle: "IT Security", policyNumber: "IT-003", effectiveDate: new Date("2021-03-20").toLocaleDateString(), reviewDate: new Date("2024-03-20").toLocaleDateString(), action: "delete" },
    { id: 4, policyTitle: "HR Policy", policyNumber: "HR-004", effectiveDate: new Date("2020-10-10").toLocaleDateString(), reviewDate: new Date("2022-10-10").toLocaleDateString(), action: "view" },
];

interface UploadInvoicesGridProps {
    currentPage: number;
    rowsPerPage: number;
    onPaginationChange: (model: GridPaginationModel) => void;
}

const InvoicesGrid: React.FC<UploadInvoicesGridProps> = ({ currentPage, rowsPerPage, onPaginationChange }) => {
    return (
        <Box
            sx={{
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "8px",
                overflow: "hidden",
                backgroundColor: "white",
                margin: '10px',
            }}
        >
            <DataGrid
                rows={staticPolicies}
                columns={InvoicesColumns()}
                paginationModel={{
                    page: currentPage,
                    pageSize: rowsPerPage,
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                rowCount={staticPolicies.length}
                paginationMode="server"
                onPaginationModelChange={onPaginationChange}
                sx={{
                    "& .MuiDataGrid-cell": {
                        border: '0.5px solid #E5E4E2',
                    },
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#cfdce4",
                    },
                    "& .MuiDataGrid-root": {
                        overflowX: "auto",
                    },
                }}
            />
        </Box>
    );
};

export default InvoicesGrid;
