import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const DropdownUser = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef<HTMLAnchorElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdown.current || !trigger.current) return;

      if (
        dropdownOpen &&
        !dropdown.current.contains(event.target as Node) &&
        !trigger.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [dropdownOpen]);

  const handleLogout = () => {
    console.log("User logged out");
  };

  return (
    <div className="relative">
      {/* <DropdownTrigger
        setDropdownOpen={setDropdownOpen}
        triggerRef={trigger}
        dropdownOpen={dropdownOpen}
      />
      <DropdownMenu
        dropdownRef={dropdown}
        dropdownOpen={dropdownOpen}
        handleLogout={handleLogout}
      /> */}
    </div>
  );
};

const DropdownTrigger = ({
  setDropdownOpen,
  triggerRef,
  dropdownOpen,
}: {
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  triggerRef: React.RefObject<HTMLAnchorElement>;
  dropdownOpen: boolean;
}) => (
  <Link
    ref={triggerRef}
    onClick={() => setDropdownOpen(!dropdownOpen)}
    className="flex items-center gap-4 cursor-pointer"
    to="#"
  >
    <span className="hidden text-right lg:block">
      <span className="block text-sm font-medium text-white">
        John Doe
      </span>
    </span>
    <span className="h-12 w-12 rounded-full bg-white">
      <img
        src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
        alt="User Avatar"
      />
    </span>
  </Link>
);

const DropdownMenu = ({
  dropdownRef,
  dropdownOpen,
  handleLogout,
}: {
  dropdownRef: React.RefObject<HTMLDivElement>;
  dropdownOpen: boolean;
  handleLogout: () => void;
}) => (
  <div
    ref={dropdownRef}
    className={`absolute -right-5 mt-5 w-52 flex flex-col rounded-lg border border-stroke bg-white shadow-lg dark:border-strokedark dark:bg-boxdark ${
      dropdownOpen ? "block" : "hidden"
    }`}
    style={{ zIndex: 100 }}
  >
    <ul className="flex flex-col gap-5 px-6 py-4">
      <DropdownItem icon={"/images/profile.png"} label="Profile" link="#" />
      <DropdownItem icon={"/images/settings.png"} label="Settings" link="#" />
      <li>
        <button
          onClick={handleLogout}
          className="flex items-center gap-3.5 text-sm font-medium lg:text-base"
        >
          <img src={"/images/logout.png"} alt="Logout" className="w-7 h-6" />
          Logout
        </button>
      </li>
    </ul>
  </div>
);

const DropdownItem = ({
  icon,
  label,
  link,
}: {
  icon: string;
  label: string;
  link: string;
}) => (
  <li className="border-b border-black pb-3">
    <Link
      to={link}
      className="flex items-center gap-3.5 text-sm font-medium lg:text-base"
    >
      <img src={icon} alt={label} className="w-7 h-6" />
      {label}
    </Link>
  </li>
);

export default DropdownUser;
