import React from "react";
import StartNewClaim from "../../modules/startNewClaim/startNewClaim";
const index = (props?: any) => {
  return (
    <div >
      <StartNewClaim {...props} />
    </div>
  );
};

export default index;
