import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: "https://claims-detail-service-gvctcaf6athtdyap.eastus2-01.azurewebsites.net/api/",
  // baseURL:'http://localhost:8087/api',
  // baseURL:"https://claims-service.azurewebsites.net/api/",
  // baseURL:
  //   "https://dev-claim-service-dverezamgbfbdqg3.eastus2-01.azurewebsites.net/api",
  baseURL:"https://api.ampcusforensics.net/api",
  headers: {
    // "Content-Type": "application/json",
  },
});

export default axiosInstance;
