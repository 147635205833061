import {createSlice} from '@reduxjs/toolkit';
import { attemptInterview, scheduleInterview ,getScheduleList} from 'src/api/interview';

interface clientState{
    loading:boolean,
    error:boolean,
    message:any, 
    interviewScheduledRes:any
    attemptedInterviewRes:any
    scheduleListLoading:boolean
    scheduleListError:boolean
    scheduleListMessage:any
    getScheduleListData:any
  
}
const initialState :clientState ={
    loading:false,
    error:false,
    message:"",
    interviewScheduledRes:null,
    attemptedInterviewRes:null,
    scheduleListLoading:false,
    scheduleListError:false,
    scheduleListMessage:"",
    getScheduleListData:null
}
const interviewSlice = createSlice({
    name: "Interviews",
    initialState,
    reducers:{},
    extraReducers:(builder)=> {
        builder
        .addCase(scheduleInterview.pending,(state)=>{
            state.loading= true
            state.message = "";
            state.error=false
        })
        .addCase(scheduleInterview.fulfilled,(state,action)=>{
            state.loading= false
            state.message = action.payload;
            state.error=false
            
        })
        .addCase(scheduleInterview.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.error=true;
            state.interviewScheduledRes= null
        })
        .addCase(attemptInterview.pending,(state)=>{
            state.loading= true
            state.error=false
            state.message=""
        })
        .addCase(attemptInterview.fulfilled,(state,action)=>{
            state.loading= false
            state.error=false
            // state.message=action.payload?.message
        })
        .addCase(attemptInterview.rejected, (state, action) => {
            state.loading = false;
            state.error=true
            state.message = action.payload;
        })
        .addCase(getScheduleList.pending,(state)=>{
            state.scheduleListLoading= true
            state.scheduleListError=false
            state.scheduleListMessage=""
            state.getScheduleListData=null
        })
        .addCase(getScheduleList.fulfilled,(state,action)=>{
            state.scheduleListLoading= false
            state.scheduleListError=false
            state.scheduleListMessage=action.payload?.message
            state.getScheduleListData= action.payload?.data
            
        })
        .addCase(getScheduleList.rejected, (state, action) => {
            state.scheduleListLoading = false;
            state.scheduleListError=true
            state.scheduleListMessage = action.payload;
            state.getScheduleListData=null
        })
    }
});
export default interviewSlice.reducer