import React, { useEffect } from 'react'
// import Cookies from 'js-cookie';
import {withCookies} from 'react-cookie';
 function ValidateAuth(props:any) {
  useEffect(()=>{
    fetchAccessToken()
  },[])

  const fetchAccessToken=async()=>{
    const {cookies} = props;
    const accessToken=await cookies.get('accessToken')
    console.log("accessToken::"+accessToken)
    const myCookie = getCookie('accessToken');
 console.log(myCookie);
  }

  function getCookie(name:string) {
    const value = `; ${document.cookie}`;
    const parts:any = value.split(`; ${name}=`);
  
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
  

  

  return (
    <div>ValidateAuth</div>
  )
}

export default withCookies(ValidateAuth)

