import { GridColDef } from "@mui/x-data-grid";
import Claim from "./Claim";

const columnDefs = (onClaimClick: (claim: Claim) => void): GridColDef<Claim>[] => [
    {
        field: "fileNumber",    
        headerName: "Ampcus File Number",
        flex:1,
        sortable: true,
        renderCell: (params) => (
            <div>
                <span className="cursor-pointer">{params.value}</span>
            </div>
        ),
    },
    {
        field: "claimNumber",
        headerName: "Claim Number",
        flex:1,
        sortable: true,
        renderCell: (params) => (
            <div onClick={() => onClaimClick(params.row)}>
                <span className="text-[#0b8ce8] underline cursor-pointer">{params.value}</span>
            </div>
        ),
    },
    { field: "clientName", headerName: "Client Name",  flex:1,sortable: true,
        renderCell: (params:any) => (
        <div onClick={() => console.log(params.row)}>
            <span className="">{params.row?.clientDetails?.firstName} {params.row?.clientDetails?.lastName}</span>
        </div>
    ), },
    { field: "insuredBusinessName", headerName: "Insured Business Name", width: 180,sortable: true },
    {
        field: "assignee",
        headerName: "Assigned Accountant",
        flex:1,
        sortable: true
    },
    {
        field: "status",
        headerName: "Status",
        flex:1,
        sortable: true,
        renderCell: (params:any) => (
            // <span className={`status-text ${params.value === "Active" ? "text-[#008000]" : params.value === "Pending" ? "text-[#ffa500]" : "text-[#ff0000]"}`}>
            <span className="font-bold">    
            {params.row?.workItem?.WorkStatus||"-"}
            </span>
        ),
    },
];

export default columnDefs;
