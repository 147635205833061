import React, { useCallback, useState } from "react";
import { Input } from "@material-tailwind/react";

interface Iprops {
  ref?: any;
  id?: string;
  required?: boolean;
  maxLength?: number;
  className?: any;
  value?: any;
  style?: any;
  minDateTime?: string |Date | undefined;
  maxDateTime?:string | Date | undefined;
  size?: 'md' | 'lg';
  type?: "number" | "text" | "email" | "date" | "tel" | "password" |"";
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  labelProps?: any;
  containerProps?: any;
  error?: boolean;
  success?: boolean;
  icon?: any;
  label?: any;
  key?:any;
  onBlur?: (value: any) => void;
  onChange: (value: any) => void;
  onFocus?: (value: any) => void;
  allowNumberOnly?:boolean
}
const FormInput: React.FC<Iprops> = ({
  ref,
  id,
  required,
  maxLength,
  className,
  style,
  type,
  size,
  placeholder,
  name,
  value,
  disabled,
  key,
  labelProps,
  containerProps,
  icon,
  label,
  onBlur,
  onFocus,
  onChange,
  minDateTime,
  maxDateTime,
  allowNumberOnly=false
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleBlur = useCallback(
    (e: any) => {
      setIsTouched(true);
      if (!value) {
        setHasError(true);
      } else {
        setHasError(false);
      }

      if (onBlur) onBlur(e);
    },
    [value, onBlur]
  );

  const handleFocus = useCallback(
    (e: any) => {
      setIsTouched(true);
      if (onFocus) onFocus(e);
    },
    [onFocus]
  );

  const renderLabel = () => (
    <label className={`text-xs font-medium mb-1 ${hasError && isTouched && !value && required ? "text-red-500" : ""}`}>
      {label}
      {/* {hasError && isTouched && !value && (
        <span className="text-red-500 text-xs mt-1">{" is required"}</span>
      )} */}
      {required && <span className="text-red-500"> *</span>}
    </label>
  );

  return (
    <div className="flex flex-col">
      {label && renderLabel()}
      <Input
        ref={ref}
        id={id}
        required={required}
        maxLength={maxLength}
        min={minDateTime}
        max={maxDateTime}
        className={`${className}
     
        ${hasError && isTouched && !value ? 'border-red-500 ' : ''}`}
        style={style}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        key={key}
        size={size}
        disabled={disabled}
        labelProps={{
          className: "hidden",
        }}
        containerProps={{ className: "min-w-[100px]" }}

        onChange={(e: any) =>{onChange(e)}}
        icon={icon}
        label={label}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={hasError && isTouched && !value ? hasError : false}
      />
    </div>
  );
};

export default FormInput;
