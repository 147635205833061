import { createSlice } from "@reduxjs/toolkit";
import { downloadDocumets, getClaimDocumets, saveTranscriptDocumets } from "src/api/documentApi";

interface propState {
  loading: boolean;
  error: boolean;
  message: any;
  documents: Array<any>;
}
const initialState: propState = {
  loading: false,
  error: false,
  message: "",
  documents: [],
};
const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClaimDocumets.pending, (state) => {
        state.loading = true;
        state.documents = [];
      })
      .addCase(getClaimDocumets.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload?.data;
        state.error = false;
      })
      .addCase(getClaimDocumets.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = true;
        state.documents = [];
      })
      .addCase(downloadDocumets.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message=""
      })
      .addCase(downloadDocumets.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = false;
      })
      .addCase(downloadDocumets.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = true;
      })
      .addCase(saveTranscriptDocumets.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message=""
      })
      .addCase(saveTranscriptDocumets.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = false;
      })
      .addCase(saveTranscriptDocumets.rejected, (state, action) => {       
        state.loading = false;
        state.message = action.payload;
        state.error = true;
      });
  },
});
export default documentsSlice.reducer;
