import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Claim from "../../modules/Dashboard/Claim";

interface FilterState {
    claimNumber: string;
    ampcusFileNumber: string;
    insuredBusinessName: string;
    assignedAccountant: string;
    clientName: string;
}

interface ClaimState {
    filters: FilterState;
    searchQuery: string;
    selectedClaim: Claim | null;
}

const initialState: ClaimState = {
    filters: {
        claimNumber: "",
        ampcusFileNumber: "",
        insuredBusinessName: "",
        assignedAccountant: "",
        clientName: "",
    },
    searchQuery: "",
    selectedClaim: null,
};

const dashboardSlice = createSlice({
    name: "claims",
    initialState,
    reducers: {
        updateClaimNumber(state, action: PayloadAction<string>) {
            state.filters.claimNumber = action.payload;
        },
        updateFileNumber(state, action: PayloadAction<string>) {
            state.filters.ampcusFileNumber = action.payload;
        },
        updateBusinessNumber(state, action: PayloadAction<string>) {
            state.filters.insuredBusinessName = action.payload;
        },
        updateAssignedAccountant(state, action: PayloadAction<string>) {
            state.filters.assignedAccountant = action.payload;
        },
        updateClientName(state, action: PayloadAction<string>) {
            state.filters.clientName = action.payload;
        },
        updateSearchQuery(state, action: PayloadAction<string>) {
            state.searchQuery = action.payload;
        },
        resetClaims() {
            return initialState;
        },
    },
});

export const {
    updateClaimNumber,
    updateFileNumber,
    updateBusinessNumber,
    updateAssignedAccountant,
    updateClientName,
    updateSearchQuery,
    resetClaims,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
