import React, { useState, ReactNode } from "react";
import Header from "../Features/Header";
import Sidebar from "../Features/Sidebar";
import { Outlet } from "react-router-dom";

interface PropsState{
  children?:ReactNode,
  hideSidebar?:boolean
  hideHeader?:boolean
}
const DefaultLayout: React.FC<PropsState> = ({hideSidebar,hideHeader}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark bg-white font-verdana h-[100vh]">
      <div className="flex h-screen overflow-hidden">
        {hideSidebar ?
          null :
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        }
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden h-[110vh]">
          <Header sidebarOpen={sidebarOpen} hideHeader={hideHeader} setSidebarOpen={setSidebarOpen} hideSideBar={hideSidebar} />
          <main className="flex-1">
            <div className={`mx-auto max-w-screen bg-white h-[100vh] ${hideHeader?"":"p-2 md:p-4 2xl:p-6"}`}>
              <Outlet />
            </div>
            {/* {hideSidebar?
             <div className="mx-auto max-w-screen max-h-screen p-2 md:p-4 2xl:p-6 bg-white  ">
             <Outlet />
           </div>
          :
            <div className="mx-auto max-w-screen p-2 md:p-4 2xl:p-6 bg-white ">
              <Outlet />
            </div>
} */}
          </main>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
