// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgimage {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.form-card {
  margin: 10px 0 10px 0;
  padding: 20px;
  border: 2px solid #d3d3d3;
  border-radius: 20px;
  margin-right: 60px;
  background-color: #fff;
  position: fixed !important;
  /* top: 14%; */
  /* left: 50%; */
  /* background: rgba(13, 13, 13, 0.4); */
  /* height: 80% !important; */
  /* width: 48% !important; */
}
`, "",{"version":3,"sources":["webpack://./src/modules/startNewClaim/newClaim.css"],"names":[],"mappings":"AAAA;EACE,yDAAmD;AACrD;AACA;EACE,qBAAqB;EACrB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;EACtB,0BAA0B;EAC1B,cAAc;EACd,eAAe;EACf,uCAAuC;EACvC,4BAA4B;EAC5B,2BAA2B;AAC7B","sourcesContent":[".bgimage {\n  background-image: url(\"../../assets/images/bg.jpg\");\n}\n.form-card {\n  margin: 10px 0 10px 0;\n  padding: 20px;\n  border: 2px solid #d3d3d3;\n  border-radius: 20px;\n  margin-right: 60px;\n  background-color: #fff;\n  position: fixed !important;\n  /* top: 14%; */\n  /* left: 50%; */\n  /* background: rgba(13, 13, 13, 0.4); */\n  /* height: 80% !important; */\n  /* width: 48% !important; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
