import React from "react";
import ClaimOne from "../../assets/images/claim1.png";
import ClaimTwo from "../../assets/images/claim2.png";
const leftSideSection = () => {
  return (
    <div className="w-1/2 justify-center h-[90vh] hidden lg:block md:block bg-[#ECECEC]">
      <div className="flex p-4">
        <span className="font-poppins antialiased text-xl font-semibold">
          Fast
        </span>
        <div className="w-2 h-2 rounded-xl bg-[#1F7FBB] relative top-[10px] left-[10px]"></div>
        <span className="font-poppins antialiased text-xl ml-5 font-semibold">
          Online
        </span>
        <div className="w-2 h-2 rounded-xl bg-[#1F7FBB] relative top-[10px] left-[10px]"></div>
        <span className="font-poppins antialiased text-xl ml-5 font-semibold">
          Paperless
        </span>
      </div>
      <div className="welcome-info px-4">
        <span className="font-bold antialiased text-xl break-words block">
          Easy online Form to 
        </span>
        <span className="font-bold antialiased text-xl break-words">
           start a claim
        </span>
        <p className="font-bold antialiased text-xl text-[#D9D9D9] relative top-[-10px]">
          Easy online Form to 
        </p>
        <p className="font-bold antialiased text-xl text-[#D9D9D9] relative top-[-10px]">
          start a claim
        </p>
      </div>
      <div>
        <img src={ClaimTwo} width={350} className="relative right-[5%]" />
        <img src={ClaimOne} width={300} className="absolute top-[45%]  m-auto" />
      </div>
    </div>
  );
};

export default leftSideSection;
