import { GridColDef } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';

const renderHeader = (title: string) => (
    <div className="hover:text-[#0b8ce8] cursor-pointer">
        {title}
    </div>
);

const renderCell = (value: string) => (
    <div className="text-blue-gray-600 text-center hover:text-[#0b8ce8] cursor-pointer">
        {value}
    </div>
);

const FinancialStatementColumns = (): GridColDef[] => [
    {
        field: "policyTitle",
        width: 180,
        headerAlign: 'center',
        renderHeader: () => renderHeader("POLICY TITLE"),
        renderCell: ({ value }) => renderCell(value),
    },
    {
        field: "policyNumber",
        width: 200,
        headerAlign: 'center',
        renderHeader: () => renderHeader("POLICY NUMBER"),
        renderCell: ({ value }) => renderCell(value),
    },
    {
        field: "effectiveDate",
        width: 200,
        headerAlign: 'center',
        renderHeader: () => renderHeader("EFFECTIVE DATE"),
        renderCell: ({ value }) => renderCell(value),
    },
    {
        field: "reviewDate",
        width: 180,
        headerAlign: 'center',
        renderHeader: () => renderHeader("REVIEW DATE"),
        renderCell: ({ value }) => renderCell(value),
    },
    {
        field: "action",
        width: 180,
        headerAlign: 'center',
        renderHeader: () => renderHeader("ACTION"),
        renderCell: (params) => (
            <div className="text-center">
                <VisibilityIcon
                    className="cursor-pointer text-[#434343]"
                    onClick={() => handleViewClick(params.row.id)}
                />
            </div>
        ),
    },
];

const handleViewClick = (id: number) => {
    console.log(id);
};

export default FinancialStatementColumns;
