import { useEffect, useState } from "react";

interface Props {
  onChange: (e: string) => void;
  value: string;
  minDateTime?: Date | undefined;
  maxDateTime?: Date | undefined;
  className?: string;
  error?: boolean;
  errorText?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const DateTimePicker: React.FC<Props> = ({
  onChange,
  value,
  minDateTime,
  maxDateTime,
  className,
  error,
  errorText,
  onBlur
}) => {
  const [dateValue, setDateValue] = useState<string>("");
  useEffect(() => {
    setDateValue(
      (value && value.length > 3
        ? value.replace("T", " ").replace(":00.32120", "")
        : "") || value
    );
  }, [value]);

  const handleChange = (e: string) => {
    setDateValue(e);
    onChange(e + ":00.32120");
  };

  return (
    <div className="w-full">
      <input
        min={minDateTime
          ? minDateTime.toISOString().slice(0, 16)  // Extracts "YYYY-MM-DDTHH:MM" from ISO string
          : undefined}
        max={maxDateTime
          ? maxDateTime.toISOString().slice(0, 16)  // Extracts "YYYY-MM-DDTHH:MM" from ISO string
          : undefined}
        className={`${className
            ? className
            : "border-2 text-sm w-full p-2 outline-blue-600 h-9 rounded-lg"
          } ${error && "!border-2 !border-red-600"}`}
        type="datetime-local"
        value={dateValue}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={onBlur}
      />
      {errorText && errorText.length && (
        <div className="text-sm mt-[-1] text-red-600">{errorText}</div>
      )}
    </div>
  );
};

export default DateTimePicker;
