import React, { useCallback, useState } from "react";

interface Iprops {
  id?: string;
  className?: any;
  items: Array<any>;
  selectedItem?: any;
  size?: "md" | "lg";
  disabled?: boolean;
  required?: boolean;
  onChange: (value: any) => void;
  onBlur?: (value: any) => void;
  onFocus?: (value: any) => void;
  name?: string;
  type?: "default" | "custom";
  ref?: any;
  label?: string;
}

const FormSelect: React.FC<Iprops> = ({
  id,
  className,
  items,
  selectedItem,
  disabled,
  required,
  onChange,
  onBlur,
  onFocus,
  name,
  ref,
  label
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleBlur = useCallback(
    (e: any) => {
      setIsTouched(true);
      if (!selectedItem.value) {
        setHasError(true);
      } else {
        setHasError(false);
      }
      if (onBlur) onBlur(e);
    },
    [selectedItem.value, onBlur]
  );

  const handleFocus = useCallback(
    (e: any) => {
      setIsTouched(true);
      if (onFocus) onFocus(e);
    },
    [onFocus]
  );

  const renderLabel = () => (
    <label className={`text-xs font-medium mb-1 ${hasError && isTouched && !selectedItem.value ? "text-red-500" : ""}`}>
      {label}
      {/* {hasError && isTouched && !selectedItem.value && (
        <span className="text-red-500 text-xs mt-1">{" is required"}</span>
      )} */}
      {required && <span className="text-red-500"> *</span>}
    </label>
  );

  return (
    <div className="flex flex-col">
      {label && renderLabel()}
      <select
        id={id}
        className={`${className} ${hasError ? 'border-red-500' : ''}`}
        value={selectedItem?.value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        name={name}
        ref={ref}
        onBlur={handleBlur}
        onFocus={handleFocus}
      >
        <option value="">Select</option>
        {items.map((item?: any, key?: number) => (
          <option value={item.value} key={key}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormSelect;
