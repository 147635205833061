import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClaimData } from "../../modules/startNewClaim/ClaimData";
import { RootState } from "..";
import { checkClaimNumberPresent, getClaimDetailsById, getClaimList, getFileNumber, updateClaimByClaimId } from "src/api/claimApi";
// import { RootState } from "..";

type ClientInfo = ClaimData['newClientDetails'];
type ClaimInfo = Pick<ClaimData, 'fileNumber' | 'claimNumber' | 'assignee'|'assigneeEmail' | 'dateOfLoss'|'comments'|'status'>;
type InsuredInfo = Pick<ClaimData, 'insuredFirstName'|'insuredLastName' | 'insuredTitle' | 'insuredBusinessName' | 'insuredPostalAddress' | 'insuredPhoneNumber' | 'insuredEmail'|'requiredOtherInfo'>;

interface ClaimState {
    clientInfo: ClientInfo;
    claimInfo: ClaimInfo;
    insuredInfo: InsuredInfo;
    loading:boolean
    error?:string
    data?:any
    claimDetails:any
    updatedClaimData?:any
    latestFileNumber?:any
    isUniqueClaimNumber?:boolean
}
const clientInfo=  {
    clientId: "",
    clientName: "",
    clientTitle: "",
    clientCompany: "",
    phoneNumber: "",
    email: "",
    comments:"",
    firstName:"",
    lastName:"",
    middleName:"",
    
}
const claimInfo={
    fileNumber: "",
    claimNumber: "",
    assignee: "" ,
    assigneeEmail:"",
    dateOfLoss: "",
    comments:"",
    
}
const insuredInfo={
    insuredFirstName: "",
    insuredLastName:"",
    insuredTitle: "",
    insuredBusinessName: "",
    insuredPostalAddress: "",
    insuredPhoneNumber: "",
    insuredEmail: "",
    requiredOtherInfo:true
}
const initialState: ClaimState = {
    isUniqueClaimNumber:false,
    loading:false,
    data:[],
    claimDetails:null,
    updatedClaimData:null,
    error:"",
    clientInfo: {...clientInfo},
    claimInfo:{...claimInfo} ,
    insuredInfo: {...insuredInfo},
};

const claimSlice = createSlice({
    name: "claim",
    initialState,
    reducers: {
        setClientInfo: (state, action: PayloadAction<ClientInfo>) => {
            state.clientInfo = action.payload;
        },
        setClaimInfo: (state, action: PayloadAction<ClaimInfo>) => {
            state.claimInfo = action.payload;
           
        },
        setInsuredInfo: (state, action: PayloadAction<InsuredInfo>) => {
            state.insuredInfo = action.payload;
        },
        updateClaimNumberStatus: (state) => {
            state.isUniqueClaimNumber = true;
        },
        resetClaimData:(state,action)=> {
            if(action?.payload==="ALL"){
                state.clientInfo=clientInfo
                state.claimInfo={...claimInfo,fileNumber:state?.claimInfo?.fileNumber}
                state.insuredInfo=insuredInfo
                state.claimDetails=null
                state.updatedClaimData=null
            }
            // return initialState;
        },

        
    },
    extraReducers: (builder) => {
        builder
          .addCase(getClaimList.pending, (state) => {
            state.loading = true;
            state.error = "";
          })
          .addCase(getClaimList.fulfilled, (state, action) => {
            state.loading = false; 
            state.error ="";
            state.data = action.payload;
           
          })
          .addCase(getClaimList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(getClaimDetailsById.pending, (state) => {
            state.loading = true;
            state.error = "";
          })
          .addCase(getClaimDetailsById.fulfilled, (state, action) => {
            state.loading = false; 
            state.error ="";
            state.claimDetails = action.payload?.data;
           
          })
          .addCase(getClaimDetailsById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        .addCase(updateClaimByClaimId.pending, (state) => {
            state.loading = true;
            state.error = "";
            state.updatedClaimData=null
          })
          .addCase(updateClaimByClaimId.fulfilled, (state, action) => {
            state.loading = false; 
            state.error ="";
            state.updatedClaimData = action.payload?.data;
           
          })
          .addCase(updateClaimByClaimId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            state.updatedClaimData=null
        }) 

        .addCase(getFileNumber.pending, (state) => {
            state.loading = true;
            state.error = "";
            state.latestFileNumber=null
          })
          .addCase(getFileNumber.fulfilled, (state, action) => {
            state.loading = false; 
            state.error ="";
            state.claimInfo.fileNumber=action.payload?.data
            state.latestFileNumber = action.payload?.data;
           
          })
          .addCase(getFileNumber.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            state.latestFileNumber=null
        }) 
        .addCase(checkClaimNumberPresent.pending, (state) => {
          
            state.isUniqueClaimNumber=false
          })
          .addCase(checkClaimNumberPresent.fulfilled, (state, action) => {
         
            state.isUniqueClaimNumber = action.payload?.data;
          })
          .addCase(checkClaimNumberPresent.rejected, (state, action) => {
         
            state.isUniqueClaimNumber=false
        }) 
    }
});
    



export const claim = (state: RootState) => state.claim;

export const { setClientInfo, setClaimInfo, setInsuredInfo, resetClaimData,updateClaimNumberStatus } = claimSlice.actions;
export default claimSlice.reducer;
